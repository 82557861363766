var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Basic"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('app-timeline', [_c('app-timeline-item', {
    attrs: {
      "title": "Goal Achieved",
      "subtitle": "All milestones are completed",
      "time": "few minutes ago",
      "variant": "success"
    }
  }), _c('app-timeline-item', {
    attrs: {
      "title": "Last milestone remain",
      "subtitle": "You are just one step away from your goal",
      "time": "3 minutes ago",
      "variant": "info"
    }
  }), _c('app-timeline-item', {
    attrs: {
      "title": "Your are running low on time",
      "subtitle": "Only 30 minutes left to finish milestone",
      "time": "21 minutes ago",
      "variant": "warning"
    }
  }), _c('app-timeline-item', {
    attrs: {
      "title": "Client Meeting",
      "subtitle": "New event has been added to your schedule",
      "time": "36 minutes ago"
    }
  }), _c('app-timeline-item', {
    attrs: {
      "title": "Product Design",
      "subtitle": "Product design added in workflow",
      "time": "1 hour ago",
      "variant": "secondary"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }